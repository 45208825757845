import React from 'react';
//import PropTypes from 'prop-types';

import {  Rect , Text } from 'react-konva';
import PlacePlanUni from './PlacePlanUni';


import {planStyles,PlanZoneRenderer} from './Utils';


const step_y=120;
//const step_y2=130;
const step_x=190;
const chair_shift = planStyles.chairRadius*2+planStyles.chairDistance;
const base_x1=1480;
const base_y1=370;
//const base_y2=base_y1-330+1680+chair_shift;
//const base_x2=base_x1-chair_shift;
const base_x3=base_x1-(chair_shift*2)-step_x*4;

const base_x4 = base_x1+550;
//const trib_off_x=140;


/* eslint-disable react/prop-types */

const PlanBackground = ({colors}) => (
  <React.Fragment>
   
  <Rect
    x={0}
    y={0}
    width={2997}
    height={2497}
    stroke={colors.line}
  />


{/* box_sal */}
<Rect
    x={base_x3-chair_shift}
    y={base_y1-330}
    width={base_x1-base_x3+chair_shift*4}
    height={1680}
    stroke={colors.line}
  />

{/* komnata */}
<Rect 
  x={900}
  y={base_y1-330+1680}
  width={(base_x1-base_x3+chair_shift*4)-(900-(base_x3-chair_shift))}
  height={700}
  stroke={colors.line}
/>

{/* tribuna */}
<Rect 
  x={2140}
  y={base_y1-330+1680}
  width={710}
  height={135}
  stroke={colors.line}
/>

{/* box_zakul */}
<Rect
    x={base_x3-550-chair_shift}
    y={base_y1-330}
    width={550}
    height={1100}
    stroke={colors.line}
  />

{/* box_sal2 */}
<Rect
    x={base_x4}
    y={base_y1-330}
    width={base_x1-base_x3+chair_shift*4}
    height={1680}
    stroke={colors.line}
  />

{/* podium */}
  <Rect
    x={base_x3-chair_shift+10}
    y={base_y1-330+10}
    width={base_x1-base_x3+chair_shift*4-20}
    height={150}
    stroke={colors.line}
  />

  <Text fill={colors.line}
    x={200}
    y={1000}
    fontSize={80} align={'center'} text={"BAR"} 
  />

  <Text  fill={colors.line}
      x={1130} y={1725}  
      fontSize={80} align={'center'} text={"MALÝ SÁL"} 
    />

  <Text  fill={colors.line}
      x={1020} y={840}  
      fontSize={80} align={'center'} text={"SÁL"} 
    />

   <Text  fill={colors.line}
      x={945} y={100}  
      fontSize={80} align={'center'} text={"PÓDIUM"} 
    />

   <Text  fill={colors.line}
      x={2320} y={100}  
      fontSize={80} align={'center'} text={"PÓDIUM"} 
    />

   <Text  fill={colors.line}
      x={2320} y={1650}  
      fontSize={80} align={'center'} text={"TRIBUNA"} 
    />

    <Text  fill={colors.line}
      x={1820} y={300}  
      fontSize={80} align={'center'} text={"LÓŽE"} 
    />

  {/* parket */}
  <Rect
    x={base_x3+6*chair_shift}
    y={base_y1}
    width={base_x1-(base_x3+9*chair_shift)+40}
    height={7*step_y+2*chair_shift+310}
    stroke={colors.line}
  />

   {/* parket2 */}
   <Rect
    x={base_x4+7*chair_shift}
    y={base_y1}
    width={base_x1-(base_x3+9*chair_shift)+40}
    height={7*step_y+2*chair_shift+310}
    stroke={colors.line}
  />


  {/* loze l5 */}
  <Rect
    x={base_x4-200}
    y={base_y1}
    width={200}
    height={7*chair_shift}
    stroke={colors.line}
  />

  {/* loze l4 */}
  <Rect
    x={base_x4-200}
    y={base_y1+7*chair_shift}
    width={200}
    height={7*chair_shift}
    stroke={colors.line}
  />

  {/* loze centralni */}
  <Rect
    x={base_x4-200}
    y={base_y1+14*chair_shift}
    width={200}
    height={13*chair_shift}
    stroke={colors.line}
  />

  {/* loze l3 */}
  <Rect
    x={base_x4-200}
    y={base_y1+27*chair_shift}
    width={200}
    height={7*chair_shift}
    stroke={colors.line}
  />

  {/* loze l2 */}
  <Rect
    x={base_x4-200}
    y={base_y1+34*chair_shift}
    width={200}
    height={7*chair_shift}
    stroke={colors.line}
  />

  {/* loze l1 */}
  <Rect
    x={base_x4-200}
    y={base_y1+41*chair_shift}
    width={200}
    height={7*chair_shift}
    stroke={colors.line}
  />

  {/* podium2 */}
  <Rect
    x={base_x4+10}
    y={base_y1-330+10}
    width={base_x1-base_x3+chair_shift*4-20}
    height={150}
    stroke={colors.line}
  />

</React.Fragment>
);


/* eslint-enable react/prop-types */



class PlacePlanBabylon extends React.Component {

  render() {
    const renderBG=PlanBackground;
    const renderZones=[
          {key:"#balkony",render:PlanZoneRenderer("BABYLON","#balkony")},
          {key:"#tribuna",render:PlanZoneRenderer("BABYLON","#tribuna")},
          {key:"#zakulisi",render:PlanZoneRenderer("BABYLON","#zakulisi")},
          {key:"#sal",render:PlanZoneRenderer("BABYLON","#sal")},
    ];
    return React.createElement(PlacePlanUni,{...this.props,renderBG,renderZones});
  }
  
}


export default PlacePlanBabylon;