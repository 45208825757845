
import Lodash from 'lodash';

function rect2points(x,y,w,h) {
  return [x,y,x+w,y,x+w,y+h,x,y+h];
}
function points2bbox(...points) {
  const ps = Lodash.chunk(Lodash.flatten(points),2);
  //console.log("points2bbox",ps);
  const res = Lodash.reduce(ps,(acc,i)=>{
    const x = i[0];
    const y = i[1];
    acc.min_x = Math.min(x,acc.min_x);
    acc.min_y = Math.min(y,acc.min_y);
    acc.max_x = Math.max(x,acc.max_x);
    acc.max_y = Math.max(y,acc.max_y);
    return acc;
  },{min_x:10000,min_y:10000,max_x:-10000,max_y:-10000});
  //console.log("res",res)
  if ((res.min_x<res.max_x) && (res.min_y<res.max_y)) {
    return {x:res.min_x,y:res.min_y,w:res.max_x-res.min_x,h:res.max_y-res.min_y};
  }
  console.error("can't calc bbox");
  return {x:1,y:1,w:100,h:100};
}



function area(pkey,zkey,...points) {
  return {
    place_map:pkey,
    zone_map_key:zkey,
    points: points,
    box:points2bbox(...points)
  }
}


const areas = [

  //BOBY
  area("BOBY","_place_bg_", rect2points(0,0,3000,3400)),

  area("BOBY","#none",[0,0,1,1]),

  area("BOBY","#A",[880,1520,  880,1930, 1120,2080, 1120,1820,  1850,1820, 1850,2080, 2090,1930, 2090,1520] ),
  area("BOBY","#B",rect2points(1285,2565,1697-1285,2846-2565),[495,1452,495,2018,971,2366,1987,2366,2487,2018,2487,1452,2330,1452,2285,1485,2285,1818,1840,2113,1840,1835,1132,1835,1132,2113,684,1818,684,1485,640,1452]),
  area("BOBY","#Cleft",[495,2030,495,2750,990,3070,1490,3070,1490,2860,1273,2860,1273,2550,1490,2550,1490,2378,968,2378]),
  area("BOBY","#Cright",[1493,2378,1991,2378,2490,2032,2490,2860,2000,3070,1493,3070,1493,2860,1708,2860,1708,2550,1493,2550]),

  area("BOBY","#G1left",rect2points(314,700,483-314,2015-700)),
  area("BOBY","#G2left",rect2points(314,2030,483-314,2595-2030)),
  area("BOBY","#G1right",rect2points(2505,700,483-314,2015-700)),
  area("BOBY","#G2right",rect2points(2505,2030,483-314,2595-2030)),

  area("BOBY","#Sleft", rect2points(70,700,235,1870)),
  area("BOBY","#Sright", rect2points(2680,700,230,1870)),

/*
  area("BOBY","#1left",rect2points(320,700,150,680),[500,1458,  500,1690, 680,1820, 680,1458]),
  area("BOBY","#1right", rect2points(2505,700,150,680), [2480,1458,  2480,1690, 2290,1820, 2290,1458]),
  area("BOBY","#1mid",[880,1520,  880,1930, 1120,2080, 1120,1820,  1850,1820, 1850,2080, 2090,1930, 2090,1520] ),
  area("BOBY","#1bot", rect2points(1290,2580,400,260)),
  area("BOBY","#2left",[350,1400, 350,2620, 480,2620, 620,2400, 620,2240, 750,2090,   850,2090, 900,1980,   480,1700, 480,1400 ]),
  area("BOBY","#2right",[2500,1400, 2610,1400, 2610,2620, 2500,2610, 2350,2400, 2350,2240, 2220,2090, 2120,2090, 2060,1970, 2500,1700]),
  area("BOBY","#2mid", [1135,2120, 1000,2040, 950,2140, 750,2140, 650,2595,  995,2845, 1265,2845, 1265,2550, 1710,2550, 1710,2845, 1980,2845,  2330, 2595,  2230,2140,  2025,2140, 1955,2045, 1830,2120, 1830,1990, 1135,1990 ]),
  area("BOBY","#3bot", [990,2865,645, 2620, 580,2620, 580,2750, 900,2990, 2095,2990, 2405, 2750, 2405,2620, 2340,2620, 1990,2865  ]),
*/

  //BVV
  area("BVV","_place_bg_", rect2points(0,0,5400,2500)),
  area("BVV","#s1", rect2points(1170,1555,620,260)),
  area("BVV","#s2", rect2points(1175,190,620,500-88)),
  area("BVV","#s3", rect2points(1895,190,380,500-88)),
  area("BVV","#s4", rect2points(3520,190,1015+252,500-88)),


  area("BVV","#s2+", rect2points(1175,602,620,88)),
  area("BVV","#s3+", rect2points(1895,602,380,88)),
  area("BVV","#s4+", rect2points(3520,602,1015+252,88)),


  //area("BVV","#s5", rect2points(4130,1648,760,337)),

  area("BVV","#s5", [   4130,1648,
                        4630,1648,
                        4630,1555,
                        4890,1555,
                        4890,1983,
                        4130,1983]),

  area("BVV","#s5+", rect2points(4130,1555,500,91)),

  area("BVV","#s6", rect2points(3245,1555+93,880,430-93)),
  area("BVV","#s6+", rect2points(3245,1555,880,91)),


  area("BVV","#sxx", rect2points(540,1555,620,190)),
  area("BVV","#s11", rect2points(540+126,190,620-126,500)),

  area("BVV","#a1", rect2points(675,810,415,615)),
  area("BVV","#a2", rect2points(1095,810,130,615)),

  area("BVV","#b1", rect2points(2570,350,585,190)),
  area("BVV","#b2", rect2points(2570,545,585,140)),

  area("BVV","#c1", rect2points(4645,810,370,615)),
  area("BVV","#c2", rect2points(4495,810,145,615)),

  area("BVV","#d1", rect2points(2570,1715,585,270)),
  area("BVV","#d2", rect2points(2570,1570,585,140)),


//BABYLON
  area("BABYLON","_place_bg_", rect2points(0,0,3000,2500)),
  area("BABYLON","#sal", rect2points(650,200,895,1500)),
  area("BABYLON","#balkony", rect2points(1780,290,290,1340)),
  area("BABYLON","#tribuna", rect2points(2100,1650,795,230)),
  area("BABYLON","#zakulisi", rect2points(110,200,515,900)),
  area("BABYLON","#komnata", rect2points(910,1725,635,675)),

  //TEST
  area("TEST","_place_bg_", rect2points(0,0,1000,1000)),
  area("TEST","#z1", rect2points(50,100,400,800)),
  area("TEST","#z2", rect2points(500,100,400,800)),

];


  
function getZoneData(place_map,zone_map_key) {
  const rec = Lodash.find(areas,{place_map,zone_map_key});
  if (!rec) { 
    console.error("can't find zone data for ",place_map,zone_map_key); 
    return area(place_map,zone_map_key,rect2points(0,0,1,1));
  }
  return rec;
}

function getPlaceData(place_map) {
  return getZoneData(place_map,"_place_bg_");
}

  


export {getZoneData,getPlaceData,rect2points,points2bbox};
